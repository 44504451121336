export default class DrawerMenu {
  constructor() {
    this.elmDrawer = document.querySelector('#js-drawer') || false
    this.elmDrawerToggleBtn = document.querySelector('#js-drawer-btn') || false
    this.attrToggle = 'data-active'
  }
  open() {
    this.elmDrawer.setAttribute(this.attrToggle, 'true')
    this.elmDrawerToggleBtn.setAttribute(this.attrToggle, 'true')
  }
  hide() {
    this.elmDrawer.setAttribute(this.attrToggle, 'false')
    this.elmDrawerToggleBtn.setAttribute(this.attrToggle, 'false')
  }
  addEvent() {
    let openFlag = false
    this.elmDrawerToggleBtn.addEventListener('click', (_ev) => {
      if (openFlag) {
        this.hide()
        openFlag = false
      } else {
        this.open()
        openFlag = true
      }
    })
  }
  init() {
    if (!this.elmDrawer) return;
    this.addEvent()
  }
}