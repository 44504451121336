import DrawerMenu from './DrawerMenu'

const init = () => {
  const drawerMenu = new DrawerMenu()
  drawerMenu.init()
}

window.addEventListener('DOMContentLoaded', () => {
  init()
})
